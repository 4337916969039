import React from 'react';
import PropTypes from 'prop-types';
import QueueAnim from 'rc-queue-anim';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import {Form, Input, Select, Button, Row, Col, message} from 'antd';
import {LoadingOutlined, SendOutlined} from '@ant-design/icons';
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';

import { gql, useMutation } from '@apollo/client';

const CONTACT_MUTATION = gql`
  mutation CreateSubmissionMutation($clientMutationId: String!, $firstName: String!, $lastName: String!, $favoriteFood: String!, $message: String!) {
    createSubmission(input: {
      clientMutationId: $clientMutationId,
      firstName: $firstName,
      lastName: $lastName,
      favoriteFood: $favoriteFood,
      message: $message
    }) {
      success
      data
    }
  }
`

const REQUEST_MUTATION = gql`
  mutation CreateRequestMutation($clientMutationId: String!, $firstName: String!, $lastName: String!, $email: String!, $companyName: String!, $description: String!, $projectName: String!) {
    createRequest(input: {
      clientMutationId: $clientMutationId,
      firstName: $firstName,
      lastName: $lastName,
      projectName: $projectName,
      description: $description,
      email: $email,
      companyName: $companyName
    }) {
      success
      data
    }
  }
`

export default function RequestForm({projectTitle = "", isPopup, handleSubmit, ...props}) {

    React.useEffect(() => {
        loadCaptchaEnginge(6);
    }, [])

    const [form] = Form.useForm();

    // const [createSubmission, { data, loading, error }] = useMutation(CONTACT_MUTATION);
    const [createRequest, { data, loading, error }] = useMutation(REQUEST_MUTATION);

    const makeid = (length) => {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    React.useEffect(() => {
        // console.log([loading, data])
        if (!loading && data) {
            const {success} = data.createRequest;
            handleSubmit(data.createRequest);
            if (success) {
                form.resetFields([
                    'firstname',
                    'lastname',
                    'projectName',
                    'description',
                    'email',
                    'companyName',
                ])
            }
        }
    }, [loading, data])

    const doSubmit = (value) => {
        const { firstname, lastname, email, companyName, projectName, description, captcha } = value;
        if (validateCaptcha(captcha)==true) {
            loadCaptchaEnginge(6);
            createRequest({
              variables: {
                clientMutationId: makeid(30),
                firstName: firstname,
                lastName: lastname,
                email: email,
                companyName: companyName,
                projectName: projectName || 'Konsultasi',
                description: description
              }
            })
        }
    
        else {
            message.error("Captcha tidak sama, ulangi pengisian captcha", 10);
            loadCaptchaEnginge(6);
        }
    };

    const getForm = () => <Form 
        
        style={{textAlign: 'left'}}
        name="basic"
        labelCol={{ span: 24 }}
        form={form}
        onFinish={(value) => {
            if (!loading) doSubmit(value)}}>
        {projectTitle !== "" && <Form.Item
                name="projectName"
                label="Nama Proyek"
                style={{display: 'inline-block', width: '100%'}}
                initialValue={projectTitle}
            >
                <Input readOnly={true}/>
            </Form.Item>}
        <Form.Item style={{ marginBottom: 0 }}>
            <Form.Item
                name="firstname"
                label="Nama Depan"
                rules={[
                    {
                        required: true,
                        message: 'Nama depan harus diisi!'
                    }
                ]}
                style={{display: 'inline-block', width: 'calc(50% - 8px)'}}
            >
                <Input placeholder="Nama Depan"/>
            </Form.Item>
            <Form.Item
                label="Nama Belakang"
                name="lastname"
                rules={[
                    {
                        required: true,
                        message: 'Nama belakang harus diisi!'
                    }
                ]}
                style={{display: 'inline-block', width: '50%', marginLeft: '8px'}}
            >
                <Input placeholder="Nama Belakang"/>
            </Form.Item>
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
            <Form.Item
                label="Email"
                name="email"
                rules={[
                    {
                        required: true,
                        message: 'Email harus diisi!'
                    }
                ]}
                style={{display: 'inline-block', width: 'calc(50% - 8px)'}}
            >
                <Input placeholder="Email"/>
            </Form.Item>
            <Form.Item
                label="Nama Perusahaan"
                name="companyName"
                style={{display: 'inline-block', width: '50%', marginLeft: '8px'}}
            >
                <Input placeholder="Nama Perusahaan"/>
            </Form.Item>
        </Form.Item>
        <Form.Item
            label="Deskripsi Proyek yang Ingin Dibuat"
            name="description"
            rules={[
                {
                    required: true,
                    message: 'Deskripsi harus diisi!'
                }
            ]}
        >
            <Input.TextArea placeholder="Deskripsi projek yang ingin dibuat" rows={5}/>
        </Form.Item>
        <Form.Item name="captcha" style={{marginBottom: 0}}>
            <div style={{display: 'flex'}}>
                <div style={{flex: 1}}>
                    <Input />
                </div>
                <LoadCanvasTemplate />
            </div>
        </Form.Item>
        <Form.Item>
            <Button type="primary" htmlType="submit">{loading ? <LoadingOutlined />: <SendOutlined/>} kirim</Button>
        </Form.Item>
    </Form>
    
    return (<div component="section" className={isPopup ? "" : "page-wrapper page2"} style={{backgroundColor: isPopup ? 'white': '#f8fafe'}}>
    <div
      className={isPopup ? "" : "page text-center"}
      key="page"
    >
      {!isPopup && 
        (<>
            <h2 key="title">Konsultasikan dengan Kami Apa yang Anda Butuhkan untuk Bisnis Anda</h2>
            <span key="line" className="separator" />
        </>)}
      <div className="info-content" key="content">
        {getForm()}
      </div>
    </div>
  </div>);
}